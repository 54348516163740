import React from 'react'
import { Columns } from '../Columns'

export function PsdPageSection() {
  return (
    <>
      <a href="https://crystalboxesprivate.github.io/">
        <h2>Launch Demo</h2>
      </a>
      <div className="bottom-padding">
        <img
          style={{
            objectFit: 'cover',
            width: '100%',
          }}
          src="/content/images/psd-inspect/web-page-screen.jpg"
          alt="PSD SDK Demo"
        />
      </div>
      <Columns
        left={() => (
          <>
            <p>
              Nowadays <b>Photoshop</b> files play an important role in sharing
              complex raster images between artists. The ability to have a layer
              information, grouping, blending modes and wide adoption between
              different applications make it de-facto the standard format for
              storing 2D assets. There's an open specification shared by Adobe
              and numerous libraries which implement this specification to some
              extent, some implementations are proprietary, others are
              incomplete.
            </p>
            <p>
              Recently my eye caught a nice PSD library, which is capable
              importing and exporting files — <b>psd_sdk</b> by{' '}
              <a href="https://molecular-matters.com/products_psd_sdk.html">
                Molecular Matters
              </a>
              . It was written in <b>C++</b>, had a very clean and
              understandable code. By that time, I was working on my
              Flutter-based image editor and I wanted the PSD support there but
              at the same time to avoid the <b>dart:ffi</b> at all costs.
            </p>
            <p>
              Then the idea of porting <b>psd_sdk</b> to dart was born. Dart is
              a statically typed language however it has its own odd
              limitations: integers and doubles are only limited to 64 bits and
              it's impossible to manipulate bytes as freely as in C or Rust
              languages. Working with byte-specific types is handled implicitly
              there: you use proxy classes via <b>dart:typed_data</b> like
              ByteData or Uint8List.
            </p>
          </>
        )}
        right={() => (
          <>
            <p>
              Nevertheless, it was pretty smooth the translate the C++ code to
              dart and test it through. Dart VM was pretty fast to execute,
              which made it easier to iterate and correct mistakes in porting.
            </p>
            <p>
              Once I finished the library, I published it to pub. My journey
              with this library hasn't stopped here: I knew that Dart had a
              strong and powerful infrastructure for transpiling the code to JS
              with smooth interop options.
            </p>
            <p>
              I gave <b>dart2js</b> a go. Unfortunately, I haven't found a way
              to build it as a Node module, however, for my test, it was enough
              to embed the script on to the page and call it from the React
              application.
            </p>
            <p>
              For a playground, I've built a <b>React</b>-based app. It has an
              interface for inspecting the document and show the layer
              structure. I quickly faced the problem of the single-threaded
              model of Javascript. It was too heavy for parsing large files on
              the same thread and, I had to find a better way to execute this.
            </p>
            <p>
              I tried to port my async code to <b>Web Workers</b> and… it
              worked. My CPU was running at 100% and, UI wasn't freezing.
            </p>
            <p>
              This project was a great way to experiment with multiple techs and
              see the potential of using dart in the existing Web App pipeline.
            </p>
          </>
        )}
      />
    </>
  )
}
