import React from 'react'
import { TestGrid } from './Grid'
import { MainHeader, SmallHeader } from './Header'
import { Layout } from './Layout'
import { MainPageSection } from './MainPageSection'
import { ContactPageSection } from './ContactPageSection'
import { Footer, FooterBig } from './Footer'
import { Helmet } from 'react-helmet'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  withRouter,
} from 'react-router-dom'
import { linkPaths } from './links'
import { projects } from './projects'
import { ProjectPageSection } from './ProjectPageSection'
import ScrollToTop from './ScrollToTop'

function MainPage() {
  return (
    <Layout footer={() => <Footer />}>
      <Helmet>
        <title>Oleg Pustovit | Portfolio</title>
      </Helmet>
      <MainHeader />
      <MainPageSection />
    </Layout>
  )
}

function ContactPage() {
  return (
    <Layout footer={() => <Footer hideEmail />}>
      <Helmet>
        <title>Contact</title>
      </Helmet>
      <SmallHeader />
      <ContactPageSection />
    </Layout>
  )
}

export default function App() {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route path={linkPaths.contact}>
            <ContactPage />
          </Route>

          {Array.from(projects.entries()).map((e) => (
            <Route key={e[1].path} path={e[1].path}>
              <Layout footer={() => <FooterBig />}>
                <Helmet>
                  <title>{e[1].name}</title>
                </Helmet>
                <SmallHeader />
                <ProjectPageSection projectData={e[1]}>
                  {React.createElement(e[1].page as any, {})}
                </ProjectPageSection>
              </Layout>
            </Route>
          ))}

          <Route path={linkPaths.home}>
            <MainPage />
          </Route>
        </Switch>
      </ScrollToTop>
    </Router>
  )
}
