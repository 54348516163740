import { PhoblocksPageSection } from './PhoblocksPage'
import { FlutterSvgPageSection } from './FlutterSvgPageSection'
import { P5FlutterPageSection } from './P5FlutterPageSection'
import { GLManPageSection } from './GLManPageSection'
import { PsdPageSection } from './PsdPage'
import { ShaderlyPageSection } from './ShaderlyPageSection'

export type TitleImage = {
  static: string
  hovered?: string
}

export type SourceCodeLink = {
  title: string
  link: string
}

export type ProjectData = {
  id: string
  titleImage?: TitleImage
  name: string
  path: string
  type: string
  framework: string[]
  dateStarted: string
  sourceCode: string | SourceCodeLink[]
  description: string[]
  tags: string[]

  page: Function
}

export const projects = new Map<string, ProjectData>([
  [
    'phoblocks',
    {
      id: 'phoblocks',
      name: 'Phoblocks',
      path: '/projects/phoblocks',
      type: 'Mobile App',
      framework: ['React.js', 'React Native', 'Flutter'],
      dateStarted: '01.06.2020',
      sourceCode: 'n/a',
      description: [
        'Design a cross-platform digital painting app for mobile devices.',
      ],
      tags: ['React', 'React Native', 'Redux', 'WebGL', 'Flutter'],

      titleImage: {
        static: '/content/images/phoblocks-title.jpg',
        hovered: '/content/video/phoblocks.mp4',
      },
      page: PhoblocksPageSection,
    },
  ],
  [
    'flutter-svg-tool',
    {
      id: 'flutter-svg-tool',
      name: 'Flutter SVG Tool',
      path: '/projects/flutter-svg-tool',
      type: 'Web App',
      framework: ['Flutter'],
      dateStarted: '02.08.2020',
      sourceCode: [
        {
          title: 'Github',
          link: 'https://github.com/crystalboxes/flutter_svg_tool',
        },
      ],
      description: [
        'A Tool which transforms SVG graphic into Flutter Custom Painter widget.',
      ],
      titleImage: {
        static: '/content/images/svg/title.jpg',
        hovered: '/content/video/svg.mp4',
      },
      tags: ['Flutter'],
      page: FlutterSvgPageSection,
    },
  ],
  [
    'p5-flutter',
    {
      id: 'p5-flutter',
      name: 'Flutter P5 API',
      path: '/projects/p5-flutter',
      type: 'Library',
      framework: ['Flutter'],
      dateStarted: '17.07.2020',
      sourceCode: [
        {
          title: 'Github',
          link: 'https://github.com/crystalboxes/p5_flutter',
        },
      ],
      description: ['Processing-like API for Flutter.'],
      tags: ['Flutter', 'Dart'],

      titleImage: {
        static: '/content/images/p5-demo/p5_title.jpg',
        hovered: '/content/video/p5.mp4',
      },

      page: P5FlutterPageSection,
    },
  ],
  [
    'gl-man',
    {
      id: 'gl-man',
      name: 'GL Man',
      path: '/projects/gl-man',
      type: 'Library',
      framework: ['n/a'],
      dateStarted: '09.07.2020',
      sourceCode: [
        {
          title: 'npm',
          link: 'https://www.npmjs.com/package/gl-man',
        },
      ],
      description: ['Minimal 2D drawinng API for WebGL.'],
      tags: ['WebGL', 'Library'],

      titleImage: {
        static: '/content/images/gl-man/gl-man.jpg',
        hovered: '/content/video/glman.mp4',
      },
      page: GLManPageSection,
    },
  ],

  [
    'shaderly',
    {
      id: 'shaderly',
      name: 'Shaderly',
      path: '/projects/shaderly',
      type: 'Mobile App',
      framework: ['React Native', 'Expo'],
      dateStarted: '09.07.2020',
      sourceCode: [
        {
          title: 'Github',
          link: 'https://github.com/crystalboxesprivate/shaderly',
        },
      ],
      description: ['Real-time shader editor for mobile.'],
      tags: ['React Native', 'Redux', 'WebGL'],

      titleImage: {
        static: '/content/images/shaderly/hex_static.png',
        hovered: '/content/video/shaderly.mp4',
      },

      page: ShaderlyPageSection,
    },
  ],
  [
    'psd-sdk',
    {
      id: 'psd-sdk',
      name: 'Photoshop PSD SDK + Demo',
      path: '/projects/psd-sdk',
      type: 'Library',
      framework: ['n/a'],
      dateStarted: '09.07.2020',
      sourceCode: [
        {
          title: 'pub.dev',
          link: 'https://pub.dev/packages/psd_sdk',
        },
      ],
      description: [
        'A Dart library that directly reads Photoshop PSD files. Supports limited export functionality.',
      ],
      titleImage: {
        static: '/content/images/psd-inspect/title.jpg',
      },

      tags: ['Dart', 'React', 'Redux', 'Web Workers', 'Library'],
      page: PsdPageSection,
    },
  ],
])
