import React from 'react'
import { Columns } from '../Columns'

export function GLManPageSection() {
  return (
    <>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridGap: 10,
        }}
      >
        <div>
          <img
            style={{ objectFit: 'cover', width: '100%' }}
            src="/content/images/gl-man/var1.jpg"
            alt={'texgen render 1'}
          />
        </div>
        <div>
          <img
            style={{ objectFit: 'cover', width: '100%' }}
            src="/content/images/gl-man/var2.jpg"
            alt={'texgen render 2'}
          />
        </div>
        <div>
          <img
            style={{ objectFit: 'cover', width: '100%' }}
            src="/content/images/gl-man/var3.jpg"
            alt={'texgen render 3'}
          />
        </div>
        <div>
          <img
            style={{ objectFit: 'cover', width: '100%' }}
            src="/content/images/gl-man/var4.jpg"
            alt={'texgen render 4'}
          />
        </div>
        <div>
          <img
            style={{ objectFit: 'cover', width: '100%' }}
            src="/content/images/gl-man/var5.jpg"
            alt={'texgen render 5'}
          />
        </div>
        <div>
          <img
            style={{ objectFit: 'cover', width: '100%' }}
            src="/content/images/gl-man/var6.jpg"
            alt={'texgen render 6'}
          />
        </div>
      </div>
      <p className="grey">
        Images generated with an React-based app <b>texgen</b>. It uses
        rectangle subdivisions with random placement to generate patterns.
        Colors are obtained with a cosine gradient function.
      </p>
      <p>
        There are many WebGL libraries out there. Three.js, babylon for 3D and
        pixi.js for 2D, to name few. It's not required to go deep into
        understanding how OpenGL or WebGL works, as the low-level context is
        well abstracted in these libraries.
      </p>

      <p>
        Many of these solutions may dictate how your rendering pipeline would
        work or how and when each frame might be rendered. Additionally, a lot
        of unnecessary code is used.
      </p>
      <p>
        I have done quite a lot of 2D rendering, mainly 2D texture generation. I
        wanted to have something similar to Canvas API but more performant as it
        suffers for a large number of draw calls. But lightweight enough to
        integrate itself into the stateful flow of WebGL code.
      </p>

      <p>
        <b>GL Man</b> is the library that helps me t do it. It supports
        primitive shape rendering, rendering to framebuffers, complex shapes
        (like bezier or <b>catmull-rom</b> curves). It's successfully running in
        WebGL/WebGL 2 environment as well as a non-browser place like{' '}
        <b>Expo GL</b>, which I utilized in my other projects.
      </p>
      <p>
        While it's library is in early stage of development, I've published it
        to npm.
      </p>
    </>
  )
}
