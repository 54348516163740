import React from 'react'
import './Header.css'
import { useLocation, Link } from 'react-router-dom'
import { linkPaths } from './links'
//@ts-ignore
import Slide from 'react-reveal/Slide'

export function MainHeader() {
  return (
    <header className="MainHeader default-gap default-grid-padding wrapper">
      <div className="title">
        <h1>Oleg Pustovit</h1>
      </div>
      <div className="desc text-body-larger">
        a creative developer based in Kiev. Specialized on creating awesome Web
        and interactive experiences
      </div>
      <div></div>
      <VerticalMenu />
    </header>
  )
}

export function CustomLink({ to, children }: { to: string; children: any }) {
  const location = useLocation()
  return (
    <Link to={{ pathname: to, state: { from: location.pathname } }}>
      {children}
    </Link>
  )
}

function VerticalMenu() {
  return (
    <div className="vertical-menu">
      <ul>
        <li className="current">Works</li>
        <li>
          <CustomLink to={linkPaths.contact}>Contact</CustomLink>
        </li>
        <li className="separator"></li>
        <li>
          <a href="https://github.com/crystalboxes">Github</a>
        </li>
        <li>
          <a href="https://twitter.com/crystalboxes">Twitter</a>
        </li>
      </ul>
    </div>
  )
}

export function SmallHeader() {
  const location = useLocation()
  console.log({ location })
  let slide = true
  if (
    location.pathname !== '/' &&
    location.state &&
    'from' in location.state &&
    (location.state as any).from !== '/'
  ) {
    slide = false
  }
  const SmallHeaderBody = () => (
    <div className="SmallHeader default-gap default-grid-padding wrapper">
      <nav>
        <div>
          <CustomLink to={linkPaths.home}>Works</CustomLink>
        </div>
        {location.pathname === linkPaths.contact ? (
          <div className="current">Contact</div>
        ) : (
          <div>
            <CustomLink to={linkPaths.contact}>Contact</CustomLink>
          </div>
        )}
      </nav>
      <div className="title">
        <CustomLink to="/">
          <b>oleg pustovit </b>| portfolio
        </CustomLink>
      </div>

      <div className="icon-buttons">
        <IconButton href="https://github.com/crystalboxes">
          <IconGithub />
        </IconButton>
        <IconButton href="https://twitter.com/crystalboxes">
          <IconTwitter />
        </IconButton>
      </div>
    </div>
  )
  return slide ? (
    <Slide top duration={500}>
      <SmallHeaderBody />
    </Slide>
  ) : (
    <div>
      <SmallHeaderBody />
    </div>
  )
}

function IconButton({
  children,
  href,
}: {
  children: JSX.Element
  href: string
}) {
  return (
    <a href={href} className="icon-button-link">
      {children}
    </a>
  )
}

function IconGithub(props: any) {
  return (
    <svg
      width={34.447 / 1.5}
      height={33.594 / 1.5}
      viewBox="0 0 34.447 33.594"
      {...props}
    >
      <path
        d="M17.223 0a17.224 17.224 0 00-5.447 33.564c.861.162 1.177-.37 1.177-.828 0-.409-.014-1.493-.022-2.928-4.791 1.039-5.8-2.311-5.8-2.311a4.565 4.565 0 00-1.916-2.519c-1.56-1.068.121-1.046.121-1.046a3.611 3.611 0 012.638 1.774 3.67 3.67 0 005.016 1.432 3.672 3.672 0 011.091-2.3c-3.825-.431-7.845-1.912-7.845-8.511a6.645 6.645 0 011.773-4.622 6.124 6.124 0 01.151-4.558s1.44-.465 4.735 1.762a16.238 16.238 0 018.612 0c3.272-2.228 4.715-1.765 4.715-1.765a6.293 6.293 0 01.172 4.558 6.672 6.672 0 011.765 4.622c0 6.617-4.026 8.073-7.858 8.5a4.123 4.123 0 011.163 3.186c0 2.305-.022 4.157-.022 4.716 0 .452.3.99 1.184.818A17.2 17.2 0 0017.223 0"
        fill="#fff"
      />
    </svg>
  )
}

function IconTwitter(props: any) {
  return (
    <svg
      width={34.677 / 1.5}
      height={28.164 / 1.5}
      viewBox="0 0 34.677 28.164"
      {...props}
    >
      <path
        d="M31.113 7.019c.022.308.022.616.022.924 0 9.4-7.151 20.221-20.221 20.221A20.084 20.084 0 010 24.974a14.7 14.7 0 001.716.088 14.233 14.233 0 008.823-3.036 7.12 7.12 0 01-6.645-4.929 8.962 8.962 0 001.342.11 7.517 7.517 0 001.87-.242 7.108 7.108 0 01-5.7-6.975v-.088a7.158 7.158 0 003.212.9 7.118 7.118 0 01-2.2-9.506 20.2 20.2 0 0014.654 7.437 8.023 8.023 0 01-.176-1.628A7.114 7.114 0 0129.2 2.244 13.993 13.993 0 0033.711.528a7.088 7.088 0 01-3.125 3.917 14.248 14.248 0 004.093-1.1 15.277 15.277 0 01-3.566 3.674z"
        fill="#fff"
      />
    </svg>
  )
}
