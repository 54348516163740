import React from 'react'
import './Sidebar.css'
//@ts-ignore
import Fade from 'react-reveal/Fade'

export function SideBar({
  title,
  children,
  className,
  largeTitle,
  bottomSection,
  fade,
}: {
  title: string
  children: JSX.Element | JSX.Element[]
  bottomSection: () => JSX.Element | JSX.Element[]
  className?: string
  largeTitle?: boolean
  fade?: boolean
}) {
  const getContent = () => (
    <>
      {largeTitle ? <h1>{title}</h1> : <h2>{title}</h2>}
      <section className="content">{children}</section>
      {bottomSection()}
    </>
  )

  return (
    <aside className={`Sidebar ${className}`}>
      {fade ? (
        <Fade bottom distance="15px" duration={800}>
          {getContent()}
        </Fade>
      ) : (
        getContent()
      )}
    </aside>
  )
}
