import React from 'react'

export function ShaderlyPageSection() {
  return (
    <>
      <p>
        Shaders are widely used everywhere where it comes to <b>GPU</b>{' '}
        programming. Nowadays with <b>Vulkan</b> and <b>DX12</b>, it's possible
        to utilize freely the GPU for any kind of processing.
      </p>
      <p>
        Fragment (pixel) shaders are often used for image processing or
        procedural image generation. When it comes to pixel manipulation, it
        wins in terms of efficiency compared to traditional CPU methods. With
        tools like <b>ShaderToy</b> and <b>GLSL Sandbox</b> it's quite popular
        to do experimental fragment shaders to render images or test various
        algorithms.
      </p>

      <p>
        I've decided to make a PoC mobile app which is inspired by{' '}
        <a href="https://hexler.net/products/kodelife">Kodelife</a>. It runs
        both on iOS and Android and developed with the help of{' '}
        <b>React Native</b> and <b>Expo</b>.
      </p>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridGap: 10,
        }}
      >
        <div>
          <img
            style={{ objectFit: 'cover', width: '100%' }}
            src="/content/images/shaderly/screen_b.jpg"
            alt={'texgen render 1'}
          />
        </div>
        <div>
          <img
            style={{ objectFit: 'cover', width: '100%' }}
            src="/content/images/shaderly/screen_c.jpg"
            alt={'texgen render 2'}
          />
        </div>
        <div>
          <img
            style={{ objectFit: 'cover', width: '100%' }}
            src="/content/images/shaderly/screen_a.jpg"
            alt={'texgen render 3'}
          />
        </div>
      </div>
      <p className="grey">
        A PoC of mobile shader editor. Error checking supported
      </p>

      <p>
        The architecture is designed around communication between the{' '}
        <b>web view</b>
        and React Native side. Web View was needed for embedding a decent code
        editor with syntax highlighting – I chose{' '}
        <a href="https://codemirror.net/">CodeMirror</a> for this task as it is
        mobile-friendly compared to other alternatives like <b>Monaco</b>.
      </p>
      <p>
        GL part, which is responsible for rendering and UI (React Native) share
        Redux state. This allows us to abstract away different parts of the
        application.
      </p>
      <p>
        At the moment only the core part of the app is developed. Integration
        with the <b>ShaderToy API</b> is planned.
      </p>
    </>
  )
}
