import React from 'react'
import './Layout.css'
import { useLocation } from 'react-router-dom'

export function Layout({
  children,
  footer,
}: {
  footer: () => JSX.Element
  children: JSX.Element | JSX.Element[]
}) {
  return (
    <div className="Layout">
      <div className="content">{children}</div>
      <div className="footer"> {footer()}</div>
    </div>
  )
}
