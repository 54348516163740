import React, { useState } from 'react'
import { Columns } from '../Columns'
import { AppLoader } from '../ProjectPageSection'

export function PhoblocksPageSection() {
  return (
    <>
      <Columns
        hasBottomBorder
        right={() => (
          <AppLoader
            title="Phoblocks demo"
            url="/apps/phoblocks"
            height={500}
          />
        )}
        left={() => (
          <>
            <h2>Demo</h2>
            <p>This is a current demo built for web.</p>
            <p>
              Note there are many rendering incompatibilities. The project was
              primarily designed to run in a phone environment. Same goes for a
              canvas navigation, it’s done only with finger gestures.
            </p>
            <p>
              The strokes are rendered as one brush point per mouse event. There
              is linear and spline interpolation to be done.
            </p>
            <p>
              <b>Eraser</b> (and opacity) rely on blending modes, which also
              don’t work for web (at the moment).
            </p>
            <p>
              I’ve attempted to build a CanvasKit (Skia) version, but the
              performance was worse than the HTML build.
            </p>
            <p>
              The visual appearance is based on a default <b>Material Design</b>{' '}
              theme and will be changed eventually.
            </p>
          </>
        )}
      />
      <p>
        I’ve always been fascinated by how flexible and powerful the modern
        raster editors are. It’s possible to manipulate millions of pixels,
        apply filters, and transform things. Nowadays even mobile devices are
        powerful enough to edit the raster images freely.
      </p>
      <p>
        iOS has a vast choice of creative apps that focus on photo editing and
        digital painting. Being an active <b>Procreate</b> user I was
        disappointed to see that application being iOS only. So I've decided to
        make a similar digital painting app which works both on Android and iOS
        platforms and would be manageable to develop for a single person.
      </p>
      <p>
        My first approach in developing such app was by using a Web (
        <b>HTML5</b> + <b>WebGL</b>) technologies. The idea seemed nice.
        Building UI is simple with frameworks like React and WebGL is a widely
        used tech which allows doing high performance calculations on GPU. For
        the base UI design, I’ve referenced tablet version of <b>Photoshop</b>.
      </p>
      <div>
        <img
          style={{ objectFit: 'cover', width: '100%' }}
          src="/content/images/phoblocks-rn.png"
          alt="Phoblocks Screenshot"
        />
      </div>
      <p className="grey">
        React.js + WebGL version. UI is laid out, rendering pipeline is working.
      </p>
      <Columns
        left={() => (
          <>
            <p>
              This approach suffers from a big problem, it's hard to maintain
              the 60fps refresh-rate on every device. Despite that, iOS/macOS
              doesn't support webGL2 which adds lots of restrictions on usable
              texture formats. So I've tried another approach, which involves
              the use of React Native and Expo GL.
            </p>
            <p>
              The biggest issue with Expo-GL was the <b>performance</b>: it's
              likely the problem lies around native to JavaScript bridge.
            </p>
            <p>
              At the end React Native approach seemed not viable in the long run
              and was abandoned.
            </p>
            <p>
              Expo gave the possibility to use native mobile components and was
              pretty great for getting the UI running. But the GL bridge was
              quite unstable: sudden crashes, sluggishness. Having the gesture
              handling translating to OpenGL canvas was troublesome.
            </p>
          </>
        )}
        right={() => (
          <>
            <p>
              I spent quite a lot of time on this project and thought that it's
              time to move on, however, there was another option which I didn't
              explore:
              <b> Flutter</b>. I didn't touch this framework at first, because
              it has its limitations, like fully closed and abstracted rendering
              layer — there was no way to access the GPU directly.
            </p>
            <p>
              Despite that, I researched how capable the rendering API was, and,
              to my surprise, it was quite flexible to do rasterization and
              compositing of raster images. I gave it a go and got a surprising
              result. The performance was buttery smooth and ease of development
              was one level above the RN approach.
            </p>
            <p>
              The app is in active development, with no source code at the
              moment. However, I'll open-source parts of the code-base, which
              might be useful to the Flutter community.
            </p>
          </>
        )}
      />
    </>
  )
}
