import React from 'react'

export function FlutterSvgPageSection() {
  return (
    <>
      <a href="https://svg.crystalboxes.com/">
        <h2>Launch Demo</h2>
      </a>
      <div className="bottom-padding">
        <img
          style={{
            objectFit: 'cover',
            width: '100%',
          }}
          src="/content/images/svg/screen.jpg"
          alt="SVG Tool"
        />
      </div>
      <p>
        React users enjoy using the SVG components, which largely simplify the
        asset pipeline. Of course, the SVG standard doesn't directly translate
        to React SVG props format 1:1. That's where tools like SVGR come in.
        Overall it makes no problem to bring vector graphics into the existing
        React project without a hustle.
      </p>
      <p>
        There is <b>flutter_svg</b> package which allows parsing and rendering
        SVGs, however, it has its limitations like no web support.{' '}
      </p>
      <p>
        SVG Tool is built on top of <b>flutter_svg</b> by swapping{' '}
        <b>dart:ui</b> classes with its own Canvas and Picture recorder
        limitations. This allows capturing the method arguments for the further
        code generation.
      </p>
      <p>
        One of the technical difficulties of this method is to represent
        rendering objects with limited data access like Paths of Paragraphs.
        Since these need to track its own stack of method calls. Note that not
        everything is properly supported yet.
      </p>
      <p>
        You could try the tool visiting the demo web-site or see the source code
        on Github.
      </p>
    </>
  )
}
