import React from 'react'
import './ContactPageSection.css'
//@ts-ignore
import Fade from 'react-reveal/Fade'

export function ContactPageSection() {
  const RevealingParagraph = ({
    children,
  }: {
    children: JSX.Element | JSX.Element[] | string
  }) => (
    <Fade left distance="20px">
      <p>{children}</p>
    </Fade>
  )
  return (
    <>
      <main className="ContactPageSection wrapper default-grid-padding">
        <h1>
          <Fade cascade>Let's work together</Fade>
        </h1>
        <div className="grid text-body default-gap">
          <Fade delay={200}>
            <div className="short-text">
              If you have some technical question, a job inquiry or just wanna
              say hello, feel free to contact me
            </div>
          </Fade>
          <div></div>
          <div className="contact-info">
            <RevealingParagraph>
              <span>
                <IosMailIcon />
              </span>
              <a href="mailto:crystalboxesgfx@gmail.com">
                crystalboxesgfx@gmail.com
              </a>
            </RevealingParagraph>
            <RevealingParagraph>
              <span>
                <IosCallIcon />
              </span>
              <a href="#">+38 093 080 54 08</a>
            </RevealingParagraph>
          </div>
        </div>
      </main>
      <div></div>
    </>
  )
}

function IosCallIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={34.99 / 1.5}
      height={34.987}
      viewBox="0 0 34.99 34.987"
      {...props}
    >
      <path
        d="M33.979 27.398a29.389 29.389 0 00-6.139-4.11c-1.84-.884-2.514-.865-3.817.073-1.084.783-1.785 1.512-3.033 1.239s-3.707-2.131-6.094-4.509-4.245-4.846-4.509-6.094.465-1.949 1.239-3.033c.938-1.3.966-1.977.073-3.817a28.808 28.808 0 00-4.108-6.139C6.252-.331 5.951-.04 5.214.225a13.528 13.528 0 00-2.177 1.157 6.567 6.567 0 00-2.614 2.76c-.519 1.12-1.12 3.206 1.94 8.653a48.285 48.285 0 008.489 11.322l.009.009.009.009a48.473 48.473 0 0011.322 8.489c5.447 3.061 7.533 2.459 8.653 1.94a6.455 6.455 0 002.76-2.614 13.528 13.528 0 001.157-2.177c.265-.737.566-1.038-.783-2.375z"
        fill="#fff"
      />
    </svg>
  )
}

function IosMailIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={34.502 / 1.5}
      height={23.886}
      viewBox="0 0 34.502 23.886"
      {...props}
    >
      <defs>
        <style>{'.prefix__a{fill:#fff}'}</style>
      </defs>
      <path
        className="prefix__a"
        d="M34.225 2.928l-8.924 9.09a.16.16 0 000 .232l6.245 6.652a1.076 1.076 0 010 1.526 1.081 1.081 0 01-1.526 0l-6.22-6.627a.17.17 0 00-.241 0l-1.518 1.543a6.678 6.678 0 01-4.761 2.007 6.812 6.812 0 01-4.86-2.065l-1.46-1.485a.17.17 0 00-.241 0l-6.224 6.627a1.081 1.081 0 01-1.526 0 1.076 1.076 0 010-1.526l6.245-6.652a.177.177 0 000-.232L.282 2.928A.163.163 0 000 3.044v18.188a2.662 2.662 0 002.654 2.654h29.194a2.662 2.662 0 002.654-2.654V3.044a.166.166 0 00-.277-.116z"
      />
      <path
        className="prefix__a"
        d="M17.251 15.186a4.509 4.509 0 003.243-1.36L33.507.581A2.607 2.607 0 0031.865 0H2.646a2.589 2.589 0 00-1.642.581l13.013 13.244a4.51 4.51 0 003.234 1.361z"
      />
    </svg>
  )
}
