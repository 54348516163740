import React from 'react'
import './FilterSelector.css'

export function FilterSelector({
  tags,
  selectedItems,
  clickCallback,
  title,
}: {
  title?: string
  tags: string[]
  selectedItems?: number[]
  clickCallback?: (id: number) => void
}) {
  const selected = (selectedItems ||
    Array.from(new Array<number>(5).keys()).map((x) => 0)) as number[]
  return (
    <div className="FilterSelector">
      <hr className="solid" />
      <h3>{title || 'Filters'}</h3>
      <div className="tags">
        {tags.map((x, i) => (
          <div
            onClick={clickCallback ? () => clickCallback(i) : () => {}}
            key={'tag' + i}
            className={
              (selected[i] === 1 ? 'active' : '') +
              (clickCallback != null ? ' pointer' : '')
            }
          >
            {x}
          </div>
        ))}
      </div>
    </div>
  )
}
