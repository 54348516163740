import React, { useRef, useState, useEffect } from 'react'
import './MainPageSection.css'
import { FooterColumns } from './FooterColumns'
import { SideBar } from './Siderbar'
import { projects, ProjectData, TitleImage } from './projects'
import { FilterSelector } from './FilterSelector'
//@ts-ignore
import Fade from 'react-reveal/Fade'
import { useHistory, useLocation } from 'react-router-dom'

export function MainPageSection() {
  const tags = useRef(
    Array.from(projects.entries())
      .map((x) => x[1].tags)
      .reduce((a, b) => [...a, ...b])
      .filter((value, index, self) => self.indexOf(value) === index)
  ).current

  const [selectedItems, setSelectedItems] = useState(tags.map((_) => 0))
  let totalSelected = selectedItems.reduce((a, b) => a + b)
  let filtered =
    totalSelected === 0
      ? []
      : Array.from(projects.entries())
          .map((x) => x[1])
          .filter((x) =>
            x.tags
              .map((tag) =>
                selectedItems
                  .map((y, i) => [y, i])
                  .filter((y) => y[0] === 1)
                  .map((y, i) => tags[y[1]])
                  .includes(tag)
              )
              .reduce((a, b) => a || b)
          )
  if (filtered.length === Array.from(projects.entries()).length) {
    totalSelected = 0
  }

  const previousTotalSelected = useRef(totalSelected)

  const FadeCard = ({ project }: { project: string }) =>
    previousTotalSelected.current !== 0 && totalSelected !== 0 ? (
      <Card project={project} />
    ) : (
      <Fade
        delay={Math.random() * (totalSelected === 0 ? 700 : 180)}
        duration={totalSelected === 0 ? 600 : 500}
      >
        <Card project={project} />
      </Fade>
    )

  useEffect(() => {
    previousTotalSelected.current = totalSelected
  })

  const renderDefaultLayout = () => (
    <>
      <div className="projects-grid default-gap">
        <FadeCard project="phoblocks" />
        <FadeCard project="gl-man" />
      </div>
      <div className="projects-grid white default-gap">
        <FadeCard project="p5-flutter" />
        <FadeCard project="psd-sdk" />
      </div>
      <div className="projects-grid default-gap">
        <FadeCard project="shaderly" />
        <FadeCard project="flutter-svg-tool" />
      </div>
    </>
  )

  const renderFilteredLayout = () => {
    var pairs: ProjectData[][] = []
    for (let i = 0; i < filtered.length; i += 2) {
      var p = []
      p.push(filtered[i])
      try {
        p.push(filtered[i + 1])
      } catch (e) {}
      pairs.push(p)
    }
    return (
      <>
        {pairs.map((x, i) => (
          <div key={'row' + i} className="projects-grid default-gap">
            {x.map((y) => (y ? <FadeCard project={y.id} key={y.id} /> : null))}
          </div>
        ))}
      </>
    )
  }

  return (
    <main className="MainPageSection default-gap default-grid-padding wrapper">
      <hr className="dashed" />
      <div></div>
      <SideBar
        fade
        bottomSection={() => (
          <FilterSelector
            clickCallback={(idx) =>
              setSelectedItems([
                ...selectedItems.map((x, i) =>
                  i === idx ? (x === 0 ? 1 : 0) : x
                ),
              ])
            }
            selectedItems={selectedItems}
            tags={tags}
          />
        )}
        className="projects-description"
        title="Projects"
      >
        <p className="text-body">
          These are my little personal projects I've been working on lately.
          Some of them are open-source libraries for Web and mobile platforms.
        </p>
        <p className="text-body">
          Others are R&D driven apps that focus a lot on graphics programming.
        </p>
      </SideBar>
      <section className="main-container">
        {totalSelected === 0 ? renderDefaultLayout() : renderFilteredLayout()}
      </section>
      <BottomBlock />
    </main>
  )
}

function BottomBlock() {
  return (
    <div className="BottomBlock">
      <hr className="dashed" />
      <div className="grid default-gap">
        <section>
          <h2>My Skills</h2>
          <FooterColumns
            columns={[
              {
                title: 'Web',
                elements: [
                  {
                    title: 'React',
                  },
                  {
                    title: 'webGL',
                  },
                  {
                    title: 'Webpack',
                  },
                ],
              },
              {
                title: 'Design',
                elements: [
                  {
                    title: 'XD',
                  },
                  {
                    title: 'Figma',
                  },
                  {
                    title: 'Photoshop',
                  },
                ],
              },
              {
                title: 'Desktop',
                elements: [
                  {
                    title: 'C++',
                  },
                  {
                    title: 'UE4',
                  },
                  {
                    title: 'Unity',
                  },
                ],
              },
            ]}
          />
        </section>
        <aside>
          <div className="resume-container">
            <h3>The Resume</h3>
            <p className="text-body">
              Get the complete info about my past work, skills, and education
              information in my CV.
            </p>
            <div className="donwload-button-container">
              <a href="/content/CV.pdf" download className="download-button">
                Download
              </a>
            </div>
          </div>
        </aside>
      </div>
    </div>
  )
}

function Card({
  project: projectName,
  placeholderTitle,
}: {
  project?: string
  placeholderTitle?: string
}) {
  const project = projects.get(projectName || '')
  const title = project?.name || placeholderTitle
  const link = project != null ? project.path : '#'
  const [isHovered, setIsHovered] = useState(false)

  const container = useRef(null)
  const video = useRef<HTMLVideoElement | null>(null)
  const [imageBounds, setImageBounds] = useState<DOMRect | null>(null)

  const dims = useRef([0, 0])
  const { width, height } = useWindowSize()

  useEffect(() => {
    if (isHovered) {
      video.current?.play()
    } else {
      video.current?.load()
    }
  }, [isHovered])

  useEffect(() => {
    if (container.current) {
      const rect = ((container.current as any) as HTMLElement).getBoundingClientRect()
      setImageBounds(rect)

      const videoDims = [1000, 700]
      const containerDIms = [rect.width, rect.height]
      const scale = Math.max(
        containerDIms[0] / videoDims[0],
        containerDIms[1] / videoDims[1]
      )
      dims.current[0] = videoDims[0] * scale
      dims.current[1] = videoDims[1] * scale
    }
  }, [container, width, height])

  const history = useHistory()
  const location = useLocation()
  // const [isHovered, setIsHovered] = useState(false)

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => history.push(link, { from: location.pathname })}
      className="Card"
    >
      <h4>
        <a href={link} className={isHovered ? 'hovered' : ''}>
          {title}
        </a>
      </h4>
      <figure
        ref={container}
        // onMouseEnter={() => setIsHovered(true)}
        // onMouseLeave={() => setIsHovered(false)}
        // style={{ overflow: 'hidden', position: 'relative' }}
        style={{
          backgroundImage: `url(${project?.titleImage?.static})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: '50%',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        {project && project.titleImage?.hovered ? (
          <video
            playsInline
            muted
            autoPlay
            ref={video}
            style={{
              // display: isHovered ? 'block' : 'none',
              position: 'absolute',
              width: dims.current[0] + 'px',
              height: dims.current[1] + 'px',
              top: isHovered
                ? (imageBounds?.height || 1) / 2 - dims.current[1] / 2 + 'px'
                : `-1000px`,
              left: (imageBounds?.width || 1) / 2 - dims.current[0] / 2 + 'px',
            }}
            loop
          >
            <source src={project.titleImage?.hovered} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : null}
      </figure>
    </div>
  )
}

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: -1,
    height: -1,
  })

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    // Add event listener
    window.addEventListener('resize', handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  return windowSize
}
