import React from 'react'

export function Columns({
  left,
  right,
  hasBottomBorder,
}: {
  left: () => JSX.Element | JSX.Element[]
  right: () => JSX.Element | JSX.Element[]
  hasBottomBorder?: boolean
}) {
  return (
    <div
      className={`two-column-grid ${hasBottomBorder ? 'bottom-border' : ''}`}
    >
      <div className="left">{left()}</div>
      <div className="right">{right()}</div>
    </div>
  )
}
