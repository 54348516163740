import React from 'react'
import './Footer.css'
import { FooterColumns } from './FooterColumns'

export function Footer({ hideEmail }: { hideEmail?: boolean }) {
  return (
    <footer className="Footer default-gap default-grid-padding wrapper">
      <div>
        <span style={{ verticalAlign: 'middle' }}>
          © 2020 Crystal Boxes. All Rights Reserved
        </span>
      </div>
      {hideEmail ? null : (
        <div style={{ textAlign: 'right' }}>
          <span style={{ verticalAlign: 'middle', marginRight: 5 }}>
            <IosMailIcon />
          </span>
          <span style={{ verticalAlign: 'middle' }}>
            <a href="mailto:crystalboxesgfx@gmail.com">
              crystalboxesgfx@gmail.com
            </a>
          </span>
        </div>
      )}
    </footer>
  )
}

function IosMailIcon() {
  return (
    <svg width={19.701 / 1.5} height={13.639 / 1.5} viewBox="0 0 19.701 13.639">
      <defs>
        <style>{'.prefix__a{fill:#fff}'}</style>
      </defs>
      <path
        className="prefix__a"
        d="M19.539 1.672l-5.1 5.19a.092.092 0 000 .133l3.566 3.8a.615.615 0 010 .871.617.617 0 01-.871 0l-3.552-3.784a.1.1 0 00-.137 0l-.867.881A3.813 3.813 0 019.86 9.909a3.889 3.889 0 01-2.771-1.18l-.833-.848a.1.1 0 00-.137 0l-3.552 3.783a.617.617 0 01-.871 0 .615.615 0 010-.871l3.566-3.8a.1.1 0 000-.133l-5.1-5.19a.093.093 0 00-.161.066v10.388a1.52 1.52 0 001.514 1.515h16.67a1.52 1.52 0 001.515-1.515V1.738a.1.1 0 00-.161-.066z"
      />
      <path
        className="prefix__a"
        d="M9.847 8.671a2.575 2.575 0 001.852-.777l7.43-7.563A1.488 1.488 0 0018.191 0H1.51a1.479 1.479 0 00-.938.331l7.43 7.563a2.575 2.575 0 001.845.777z"
      />
    </svg>
  )
}

const cols = [
  {
    title: 'Portfolio',
    elements: [
      {
        title: 'Works',
        link: '/',
      },
      {
        title: 'Contact',
        link: '/contact',
      },
    ],
  },
  {
    title: 'Projects',
    elements: [
      {
        title: 'Phoblocks',
        link: '/projects/phoblocks',
      },
      {
        title: 'GL Man',
        link: '/projects/gl-man',
      },
      {
        title: 'Flutter P5 API',
        link: '/projects/p5-flutter',
      },
    ],
  },
  {
    elements: [
      {
        title: 'Shaderly',
        link: '/projects/shaderly',
      },
      {
        title: 'PSD SDK',
        link: '/projects/psd-sdk',
      },
      {
        title: 'Flutter SVG Tool',
        link: '/projects/flutter-svg-tool',
      },
    ],
  },
]

export function FooterBig() {
  return (
    <>
      <div className="wrapper default-grid-padding">
        <hr className="dashed" />
      </div>
      <div className="FooterBig default-gap default-grid-padding text-body wrapper">
        <div className="copyright-info">
          <p>© 2020 Crystal Boxes</p>
          <p>All Rights Reserved</p>
        </div>
        <aside>
          <FooterColumns columns={cols} />
        </aside>
      </div>
    </>
  )
}
