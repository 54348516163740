import React from 'react'
import './FooterColumns.css'
import { CustomLink } from './Header'

export type FooterColumnElement = {
  title: string
  link?: string
}

export type FooterColumn = {
  title?: string
  elements: FooterColumnElement[]
}

export function FooterColumns({
  classes,
  columns,
}: {
  columns: FooterColumn[]
  classes?: string
}) {
  return (
    <div
      className="FooterColumns skills-grid default-gap text-body"
      style={{ gridTemplateColumns: `repeat(${columns.length}, 1fr)` }}
    >
      {columns.map((col, index) => (
        <div className="col" key={index}>
          <p key={`col-title${index}`}>
            {col.title ? col.title : <span>&nbsp;</span>}
          </p>
          {col.elements.map((el, index) => (
            <p key={`element:${index}`}>
              {el.link ? (
                <CustomLink to={el.link}>{el.title}</CustomLink>
              ) : (
                el.title
              )}
            </p>
          ))}
        </div>
      ))}
    </div>
  )
}
