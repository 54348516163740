import React, { useState } from 'react'
import './ProjectPageSection.css'
import { SideBar } from './Siderbar'
import { ProjectData, SourceCodeLink } from './projects'
import { FilterSelector } from './FilterSelector'
//@ts-ignore
import Fade from 'react-reveal/Fade'

export function AppLoader({
  title,
  url,
  height,
  hasBottomBorder,
  hasBottomPadding,
}: {
  title: string
  url: string
  height: number
  hasBottomBorder?: boolean
  hasBottomPadding?: boolean
}) {
  const [isLoaded, setIsLoaded] = useState(false)
  return (
    <div
      className={`iframe-wrapper ${!isLoaded ? 'loading' : ''} ${
        hasBottomPadding ? 'bottom-padding' : ''
      } ${hasBottomBorder ? 'bottom-border' : ''}`}
    >
      <iframe
        onLoad={() => setIsLoaded(true)}
        title={title}
        src={url}
        height={height}
      ></iframe>
    </div>
  )
}

export function ProjectPageSection({
  projectData,
  children,
}: {
  projectData: ProjectData
  children: JSX.Element | JSX.Element[]
}) {
  return (
    <main className="ProjectPageSection default-gap default-grid-padding wrapper">
      <SideBar
        bottomSection={() => (
          <FilterSelector title="Tags" tags={projectData.tags} />
        )}
        largeTitle
        className="projects-description"
        title={projectData.name}
      >
        <section className="project-description-grid text-body">
          <div className="title">Type</div>
          <div>{projectData.type}</div>
          <div className="title">Framework</div>
          <div>
            {projectData.framework.map((el, i) => (
              <p key={'framework' + i}>{el}</p>
            ))}
          </div>
          <div className="title">Date started</div>
          <div>{projectData.dateStarted}</div>
          <div className="title">Source Code</div>
          <div>
            {typeof projectData.sourceCode === 'string'
              ? projectData.sourceCode
              : projectData.sourceCode.map((x, i) => (
                  <p key={'link' + i}>
                    <a href={x.link}>{x.title}</a>
                  </p>
                ))}
          </div>
          <div className="title">Description</div>
        </section>
        <div className="description text-body">
          {projectData.description.map((el, i) => (
            <p key={`descr${i}`}>{el}</p>
          ))}
        </div>
      </SideBar>

      <Fade bottom duration={600} distance="10px">
        <section className="main-container text-body">{children}</section>
      </Fade>
    </main>
  )
}
