import React from 'react'
import { Columns } from '../Columns'
import { AppLoader } from '../ProjectPageSection'

export function P5FlutterPageSection() {
  return (
    <>
      <AppLoader
        // hasBottomPadding
        title="P5 demo"
        url="/apps/p5-demo"
        height={500}
      />
      <p className="grey">
        A sketch from <i>openprocessing.org</i> ported to p5_flutter. Click to
        change seed, horizontal mouse axis — complexity, vertical mouse axis —
        noise influence.
      </p>
      <p>
        <b>Flutter</b> could be compared to Unity with its amazing portability.
        It brings <b>Skia</b> with Dart to a multitude of platforms and provides
        the easiness of creating complicated dynamic layouts as well as drawing
        complex vector graphics.
      </p>
      <p>
        I've used many creative tools that allow me to create imagery
        programmatically: Houdini, openFrameworks, Unity, to name a few. All of
        them have the option to create geometric primitives and manipulate them
        with code (or visual graphs). I've also been using <b>Processing</b> for
        doing data visualization and creating images. It has a simple API, which
        allows quickly to prototype exactly what I need.
      </p>
      <p>
        <b>p5_flutter</b> is a way to bring the simplicity of Processing to the
        Dart/flutter ecosystem. It works by subclassing the P5Widget and
        inserting it into the widget tree.
      </p>
      <Columns
        left={() => (
          <p>
            The translation of the <b>dart:ui</b> rendering API to Processing
            functions were rather simple, however, some types of operations were
            not available on Flutter side – mainly function responsible for
            path/curve operations (like Catmull-rom interpolation).
          </p>
        )}
        right={() => (
          <p>
            Right now the current version of the library will be available on{' '}
            <b>Dart Pub</b>. You can check the <b>TODO</b> file to see which
            parts of the API are implemented.
          </p>
        )}
      />
    </>
  )
}
